import React, { Component } from 'react'
//import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { DiscussionEmbed } from 'disqus-react'
import Sidebar from '../../components/sidebar'
import Download from '../../components/download'
import SEO from '../../components/seo'
import { slugify } from '../../util/utilityFunctions'

import Footer from '../../components/home/footer'

import authors from '../../util/authors'
import './single-post.css'

export default class SinglePost extends Component {
  render() {
    const { data, pageContext } = this.props
    const post = data.markdownRemark.frontmatter
    const author = authors.find(x => x.name === post.author)

    const baseUrl = 'https://developandgo.com/'

    const disqusShortname = 'develop-and-go'
    const disqusConfig = {
      identifier: data.markdownRemark.id,
      title: post.title,
      url: baseUrl + pageContext.slug,
    }

    let keywords = ['blog', post.author]
    post.tags.map(tag => keywords.push(tag))

    const excerpt = data.markdownRemark.excerpt

    return (
      <>
        <SEO
          title={post.title}
          description={excerpt}
          lang="en"
          keywords={keywords}
          image={`https://developandgo.com${
            post.image.childImageSharp.fluid.src
          }`}
          url={baseUrl + pageContext.slug}
          iso={post.iso}
        />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.6.3/css/all.css"
          integrity="sha384-LRlmVvLKVApDVGuspQFnRQJjkv0P7/YFrw84YYQtmYG4nK8c+M+NlmYDCv0rKWpG"
          crossOrigin="anonymous"
        />

        <div className="blog-post">
          <div className="header">
            <div className="header-logo">
              <Link to={`/`}>
                <strong>Develop and Go</strong>
                <hr class="brace" />
              </Link>
            </div>
          </div>

          <h1 className="my-4 font-weight-bold text-center post-title">
            {post.title}
            <hr />
          </h1>

          <div className="blog-content">
            <Img
              className="blog-image"
              fluid={post.image.childImageSharp.fluid}
            />

            <div className="card-subtitle">
              <span className="text-info">{post.date}</span> by
              <span className="text-info"> {post.author}</span>
            </div>

            <div
              className="markdown"
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />

            <Download props={this.props} github={post.github} />

            <div className="m-4">
              <h3 className="text-center">Share this post</h3>
              <div className="text-center social-share-links">
                <a
                  href={`https://facebook.com/sharer/sharer.php?u=${baseUrl}${
                    pageContext.slug
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook mr-3"
                >
                  <i className="fab fa-facebook fa-lg" />
                </a>
                <a
                  href={`https://twitter.com/share?url="${baseUrl}${
                    pageContext.slug
                  }&text=${post.title}&viatwitterHandle`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter mr-3"
                >
                  <i className="fab fa-twitter fa-lg" />
                </a>
                <a
                  href={`https://plus.google.com/share?url=${baseUrl}${
                    pageContext.slug
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="google mr-3"
                >
                  <i className="fab fa-google fa-lg" />
                </a>
                <a
                  href={`https://linkedin.com/shareArticle?url=${baseUrl}${
                    pageContext.slug
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin mr-3"
                >
                  <i className="fab fa-linkedin fa-lg" />
                </a>
              </div>
            </div>

            <div className="blog-tags">
              {post.tags.map(tag => (
                <Link key={tag} className="" to={`/tags/${slugify(tag)}`}>
                  <span className="badge badge-primary text-uppercase">
                    {tag}
                  </span>
                </Link>
              ))}
            </div>

            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div>

          <div className="sidebar">
            <Sidebar
              author={author}
              authorImageFluid={data.file.childImageSharp.fluid}
            />
          </div>

          <div className="blog-footer">
            <Footer />
          </div>
        </div>
      </>
    )
  }
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!, $imgUrl: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMM Do YYYY")
        iso: date
        tags
        slug
        github
        image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      excerpt(pruneLength: 280)
    }
    file(relativePath: { eq: $imgUrl }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
