const authors = [
  {
    name: 'Omar Reyes',
    imgUrl: 'author.jpeg',
    bio:
      'Omar is a Software Developer from Tempe, Arizona. He graduated from Arizona State University with a degree in computer science. During his spare time he enjoys developing mobile applications and learning new programming languages.',
    website: 'https://ReyesOmar.com',
    facebook: 'https://www.facebook.com/Mr.OmarReyes',
    twitter: 'https://twitter.com/DevelopGo',
    linkedin: 'https://linkedin.com/in/mr-omar-reyes',
  },
]

module.exports = authors
