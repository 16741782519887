import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import './download.css'

export default class Download extends Component {
  state = {
    isMember: false,
    loading: false,
    showError: false,
    email: '',
    message: '',
  }
  componentDidMount() {
    const { props } = this.props

    if (props.location.hash === '#download') {
      this.showDownloadModal.click()
      this.popup.closePopup()
    }
  }

  inputForm() {
    if (this.state.isMember) {
      return (
        <>
          <h4>Look up your email address</h4>
          <input
            type="email"
            placeholder="Email"
            value={this.state.email}
            onChange={email => this.setState({ email: email.target.value })}
          />
          <br />
          {this.state.loading ? (
            <div className="spinner" />
          ) : (
            <>
              <button type="button" onClick={() => this.isMember()}>
                Look up
              </button>
              <span>
                Sign up{' '}
                <span
                  className="here"
                  onClick={() => this.setState({ isMember: false })}
                >
                  click here
                </span>
              </span>
            </>
          )}
        </>
      )
    }

    return (
      <>
        <h4>Please sign up to download source code</h4>
        <input
          type="email"
          placeholder="Email"
          value={this.state.email}
          onChange={email => this.setState({ email: email.target.value })}
        />
        <br />

        {this.state.loading ? (
          <div className="spinner" />
        ) : (
          <>
            <button type="button" onClick={() => this.subscribe()}>
              Download
            </button>
            <span>
              Already signed up?{' '}
              <span
                className="here"
                onClick={() => this.setState({ isMember: true })}
              >
                click here
              </span>
            </span>
          </>
        )}
      </>
    )
  }

  async subscribe() {
    this.setState({
      loading: true,
      showError: false,
    })

    const subscribe = await fetch(
      'https://1rgr6ewxve.execute-api.us-east-1.amazonaws.com/prod/subscribe',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `9RriJhm1kS4OpP3uUXWBB0430ICQDuiBiNW3mpf0`,
        },
        body: JSON.stringify({
          email: this.state.email,
        }),
      }
    )

    const json = await subscribe.json()
    const { success, message } = json

    if (success) {
      const { github } = this.props
      window.open(github)
      this.setState({
        isMember: false,
        loading: false,
        showError: false,
        email: '',
        message: '',
      })
      this.popup.closePopup()
    } else {
      this.setState({ loading: false, showError: true, message })
    }
  }

  async isMember() {
    this.setState({
      loading: true,
      showError: false,
    })

    const isMember = await fetch(
      `https://1rgr6ewxve.execute-api.us-east-1.amazonaws.com/prod/subscribe/${
        this.state.email
      }`,
      {
        method: 'GET',
        headers: {
          'x-api-key': `9RriJhm1kS4OpP3uUXWBB0430ICQDuiBiNW3mpf0`,
        },
      }
    )

    const json = await isMember.json()

    const { success } = json

    if (success) {
      const { github } = this.props
      window.open(github)
      this.setState({
        isMember: false,
        loading: false,
        showError: false,
        email: '',
        message: '',
      })
      this.popup.closePopup()
    } else {
      this.setState({
        isMember: false,
        loading: false,
        showError: true,
        message: 'Could not find email address. Please sign up.',
      })
    }
  }

  render() {
    return (
      <Popup
        ref={input => (this.popup = input)}
        trigger={
          <button
            className="btn-primary download-button"
            ref={input => (this.showDownloadModal = input)}
          >
            {' '}
            Download Source Code{' '}
          </button>
        }
        modal
      >
        {close => (
          <div className="modal">
            <span className="close here" onClick={close}>
              &times;
            </span>
            <div className="headers"> Download Source Code </div>
            <div className="request-form">
              {this.inputForm()}
              {this.state.showError && (
                <div
                  className="alert alert-danger text-center mt-3"
                  role="alert"
                >
                  <strong>{this.state.message}</strong>
                </div>
              )}
            </div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  this.popup.closePopup()
                }}
              >
                close
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}
