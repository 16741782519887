import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import './sidebar.css'

const SideBar = ({ author, authorImageFluid }) => (
  <>
    <div className="sidebar-wrapper">
      {author && ( //if author is defined return this object
        <div className="author">
          <div className="author-title text-uppercase">Author</div>
          <Img className="author-image" fluid={authorImageFluid} />
          <div className="author-name text-uppercase">{author.name}</div>
          <div
            style={{ textAlign: 'center', marginBottom: '10px' }}
            className="card-text"
          >
            {author.bio}
          </div>
          <div className="author-social-links text-center">
            <a
              href={author.website}
              target="_blank"
              rel="noopener noreferrer"
              className="website mr-3"
            >
              <i className="fas fa-browser fa-lg" />
            </a>
            <a
              href={author.facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="facebook mr-3"
            >
              <i className="fab fa-facebook fa-lg" />
            </a>
            <a
              href={author.twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="twitter mr-3"
            >
              <i className="fab fa-twitter fa-lg" />
            </a>
            <a
              href={author.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin mr-3"
            >
              <i className="fab fa-linkedin fa-lg" />
            </a>
          </div>
          <hr />
        </div>
      )}
    </div>
    <div className="recent-posts">
      <div className="recent-posts-title text-uppercase">Recent Posts</div>

      <StaticQuery
        query={sidebarQuery}
        render={data => (
          <>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id} className="post card mb-1">
                <Link to={node.fields.slug}>
                  <Img
                    className="card-image-top"
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                  />
                </Link>
                <div className="card-body">
                  <div className="card-title text-center text-uppercase mb-3">
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      />
    </div>
  </>
)

const sidebarQuery = graphql`
  query sidebarQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default SideBar
